$(".btn-search").click(function(){
  $(".input").toggleClass("active").focus;
  $(this).toggleClass("animate");
  $(".input").val("");
});

var cStars = function(nowPos) {


  for (var i = 0; nowPos + 1 > i; i++) {
    $('#star-0, #star-1, #star-2, #star-3, #star-4').toggleClass('active');
  }
}
var starsCount = $('.star.active').length;

$('#star-0, #star-1, #star-2, #star-3, #star-4').hover(function() {
  cStars($(this).index());
});

$('#star-0, #star-1, #star-2, #star-3, #star-4').click(function() {
  cStars($(this).index());
  starsCount = $('.star.active').length;
});

$('#star-0, #star-1, #star-2, #star-3, #star-4').on('mouseleave', function() {
  cStars(+starsCount - 1);
});

$(function(){
  $('.country').on('click', function(){
    $('.nav__country_item').toggleClass('active');
    if($('.nav__country_item').is(':active')){
      $('.nav__country_item').removeClass('active');
    }
    return false;
  });

  $('.lang').on('click', function(){
    $('.nav__lang_item').toggleClass('active');
    if($('.nav__lang_item').is(':active')){
      $('.nav__lang_item').removeClass('active');
    }
    return false;
  });

  $(document).mouseup(function (e){
    var country = $('.country');
    if (!country.is(e.target)
      && country.has(e.target).length === 0) {
      $('.nav__country_item').removeClass('active');
  }
});

  $(document).mouseup(function (e){
    var lang = $('.lang');
    if (!lang.is(e.target)
      && lang.has(e.target).length === 0) {
      $('.nav__lang_item').removeClass('active');
  }
});

});

$(function(){
  $('.hamburger').on('click', function(){
    $(this).toggleClass('is-active');
    $('.mobile-nav').toggleClass('visible');
    if($('.header .phones').is(':visible')){
      $('.header .phones').removeClass('visible');
    }
    return false;
  });

    $(document).mouseup(function (e){ // событие клика по веб-документу
        var div = $(".mobile-nav"); // тут указываем ID элемента
        if (!div.is(e.target) // если клик был не по нашему блоку
            && div.has(e.target).length === 0) { // и не по его дочерним элементам
            div.removeClass('visible'); // скрываем его
        }
      });

    $('.close-mobile-nav').on('click', function(){
      $('.hamburger').toggleClass('is-active');
      $('.mobile-nav').toggleClass('visible');
      return false;
    });

    $('.mobile-phones-btn').on('click', function(){
      $('.header .phones').toggleClass('visible');
      if($('.mobile-nav').is(':visible')){
        $('.mobile-nav').removeClass('visible');
      }
      return false;
    });

    // $(document).on('click', '.mobile-nav.visible .mobile-nav-list li a', function(e) {
    //   e.preventDefault();
    //   $(this).closest('.mobile-nav').removeClass('visible');
    //   $("body").removeClass("locked");
    // });
    $(document).on('click', '.mobile-nav.visible .mobile-nav-list li a', function() {
      $(this).closest('.mobile-nav').removeClass('visible');
      $("body").removeClass("locked");
    });
  });
